<template>
    <v-dialog
        v-model="dialog"
        width="720"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
        class="dialog-style"
    >
      <v-card style="overflow-y:auto;">
        <v-card-title class="primary2" style="height: 30px">
            <span class="mx-2 mt-n4 chips-icon text-color-white"  style="font-size: 14px">  Assign     {{ this.$i18n.locale === 'en' ? userData.roleData.roleNameForDisplay : userData.roleData.roleNameForDisplayESP }} To Office</span>
            <v-spacer />
            <v-icon class="mt-n4" small color="white" @click="$emit('false')">mdi-window-close</v-icon>
        </v-card-title>
        <v-card-text>
            <v-row class="p-5">
                <v-col cols="12">
                  <v-data-table dense :items="hospitals" hide-default-footer  :no-data-text="$t('no-data-available')" :loading-text="$t('loading')">
                    <template v-slot:item="{ item }">
                      <tr class="my-0 py-0">
                          <td style="height:10px !important;" class="text-left pointer px-0 mx-0 font-weight-medium" @click="selectedBranch(item)">{{item.hospitalName}}</td>
                      </tr>
                    </template>
                  </v-data-table>
<!--                    <v-simple-table dense>-->
<!--                        <template v-slot:default>-->
<!--                                <tr>-->
<!--                                    <th class="text-left">-->
<!--                                       {{$t('hospital')}}-->
<!--                                    </th>-->
<!--                                </tr>-->
<!--                                <tr-->
<!--                                    v-for="item in hospitals"-->
<!--                                    :key="item.id"-->
<!--                                    @click="selectedBranch(item)"-->
<!--                                    style="cursor:pointer;"-->
<!--                                    >-->
<!--                                    <td class="text-left">{{ item.hospitalName }}</td>-->
<!--                                </tr>-->
<!--                        </template>-->
<!--                      </v-simple-table>-->
                </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
	props: ['dialog', 'specializations', 'staffUser'],
	components: {
		// BirthdatePicker,
	},
	data () {
		return {
			loader: null,
			loading: false,
			valid: false,
			title: null,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			hospitalsFromRepo: (state) => state.hospitals.hospitals,
		}),
    ...mapGetters({
      getUserById: 'users/getUserById',
    }),
    userData () {
      return this.getUserById ? this.getUserById : null;
    },
		hospitals () {
			// we filter only those hospitals that are NOT already assigned, those thar are ssigned we are not showing as option
			var result = [];
			if (this.hospitalsFromRepo !== null) {
				for (let i = 0; i < this.hospitalsFromRepo.length; i++) {
					const hospitalIdToCheck = this.hospitalsFromRepo[i].id;
					var found = false;
					for (let j = 0; j < this.staffUser.hospitalsAssignedData.length; j++) {
						if (this.staffUser.hospitalsAssignedData[j].id === hospitalIdToCheck) {
							found = true;
							break;
						}
					}
					if (found === false) {
						result.push(this.hospitalsFromRepo[i]);
					}
				}
			}
			return result;
		},
	},
	async mounted () {
		await this.getHospitals();
	},
	methods: {
		async selectedBranch (selectedHospital) {
			const body = { HospitalId: selectedHospital.id, UserId: this.staffUser.id };
			const result = await this.$store.dispatch('hospitals/assignUserToHospital', body);
			result.hospitalName = selectedHospital.hospitalName; // we attach also hospitalName to the response back
			this.$emit('result', result);
		},
		async getHospitals () {
			await this.$store.dispatch('hospitals/getHospitals');
		},
	},
};
</script>

<template>
  <v-card>
    <v-card-title style="border-radius:0;" class="text-left card-header-add" id="fixed-card-title">
     <h5 style="color:white;">Monitoring Plans View</h5>
      <v-spacer></v-spacer>
      <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
    </v-card-title>
    <div class="font-weight-bold" style="margin:100px auto; width:90%;">
      <p class="text-left font-weight-bold">Details</p>
      <h6 class="text-left ml-1">{{ monitoring[0].tpName }}</h6>
      <h6 class="text-left ml-1">{{ monitoring[0].tpDescription }}</h6>
      <div class=" text-left my-7 ml-2" v-if="monitoring[0].treatmentPlanDevicesList.length < 1">
        <p style="font-size:15px;">No Devices found.</p>
      </div>
      <p class="text-left my-7 ml-2" v-if="monitoring[0].treatmentPlanDevicesList.length >0" >Devices</p>
      <v-expansion-panels  class="px-2" accordion>
        <v-expansion-panel class="elevation-5" v-for="(device, index) in monitoring[0].treatmentPlanDevicesList" :key="index">
          <v-expansion-panel-header hide-actions>{{ device.deviceTypeTitle }}</v-expansion-panel-header>
          <v-expansion-panel-content class="float-left">
            <p>Times</p>
            {{device?.time1}}
            {{device?.time2}}
            {{device?.time3}}
            {{device?.time4}}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class=" text-left my-7 ml-2" v-if="monitoring[0].surveysList.length < 1">
        <p style="font-size:15px;">No Surveys found.</p>
      </div>
      <p class="text-left my-7 ml-3" v-if="monitoring[0].surveysList.length >0">Assessments</p>
      <v-expansion-panels class="px-2" accordion >
        <v-expansion-panel class="elevation-5" v-for="(survey, index) in monitoring[0].surveysList" :key="index">
          <v-expansion-panel-header hide-actions>{{ survey.templateName }}</v-expansion-panel-header>
          <v-expansion-panel-content class="float-left">
            {{survey.templateDescription}}
            <p class="text-left my-5">Start Date</p>
            <p class="text-left my-n3">{{survey.startDate}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data () {
    return {
      devices: [],
    };
  },
  methods: {
    closeDrawer () {
      this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
    },
  },

  computed: {
    ...mapState({
      monitoring: state => [state.viewDetailsDrawer],
    }),
    headers () {
      const headers = [
        { text: this.$t('Device Name'), value: 'deviceTypeTitle', align: 'start pl-3' },
      ];
      return headers;
    },
  },
  watch: {

  },
  beforeDestroy () {
    this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
  },
};
</script>

<style>

</style>
